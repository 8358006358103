<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()" class="cur-a">班级列表</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">开课记录</a>
        </span>
      </div>
      <div class="framePage-body">
        <!-- <div class="operationControl " >
          <div class="searchbox" >
           <div title="班级编码:" class="searchboxItem ci-full">
              <span class="itemLabel">班级编码:</span>
              <el-input
                v-model="searchDate.projectCode"
                placeholder="请输入班级编号"
                size="small"
                onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"
                clearable
              />
            </div>
             <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            </div>
          </div>
        </div>-->
        <div>
          <span>班级课程总时长:{{ totalDuration }}</span>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
              <el-table-column label="课程名称" align="center" show-overflow-tooltip prop="courseName" width="150px" />
              <el-table-column label="培训类型" align="center" show-overflow-tooltip prop="trainTypeNamePath" width="200" />
              <el-table-column label="行业类型" align="center" show-overflow-tooltip prop="industryNamePath" minWidth="150">
                <template slot-scope="scope">{{
            scope.row.industryNamePath || "--"
          }}</template>
              </el-table-column>
              <el-table-column label="岗位类型" align="center" show-overflow-tooltip prop="postName">
                <template slot-scope="scope">{{
              scope.row.postName || "--"
            }}</template>
              </el-table-column>
              <el-table-column label="职业/工种" align="center" show-overflow-tooltip prop="occupationNamePath">
                <template slot-scope="scope">{{
              scope.row.occupationNamePath || "--"
            }}</template>
              </el-table-column>
              <el-table-column label="培训等级" align="center" show-overflow-tooltip prop="trainLevelName">
                <template slot-scope="scope">{{
              scope.row.trainLevelName || "--"
            }}</template>
              </el-table-column>

              <el-table-column label="总学时" align="center" show-overflow-tooltip prop="lessonNum" />
              <el-table-column label="课件数量" align="center" show-overflow-tooltip prop="kpointTotalNum" />
              <el-table-column label="视频总时长" align="center" show-overflow-tooltip prop="kpointTotalDuration"
                minWidth="120">
                <template slot-scope="scope">{{
              getTime(scope.row.kpointTotalDuration) || "--"
            }}</template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="480px" fixed="right">
                <div slot-scope="scope">
                  <el-button style="padding: 0 5px" type="text" size="mini" @click="
          downloadQualification(
            scope.row.courseName,
            scope.row.projectCourseId,
            false
          )
            ">下载讲师资质</el-button>
                  <el-button style="padding: 0 5px" type="text" size="mini" @click="
            downloadQualification(
              scope.row.courseName,
              scope.row.projectCourseId,
              true
            )
            ">下载讲师资质(运营)</el-button>
                  <el-button style="padding: 0 5px" type="text" size="mini" @click="
            downloadTimetable(
              scope.row.courseId,
              scope.row.projectCourseId
            )
            ">下载课表</el-button>
                  <el-button style="padding: 0 5px" type="text" size="mini"
                    @click="seeDetails(scope.row)">预览课程</el-button>
                  <el-button type="text" style="padding: 0 5px" size="mini" :disabled="searchDate.projectSource == '80' ||
            (searchDate.projectSource == '90' &&
              searchDate.auditState == '30')
            " v-if="!scope.row.paperId" @click="watchTime(scope.row)">设置结业考试</el-button>
                  <el-button type="text" style="padding: 0 5px" size="mini" :disabled="scope.row.paperSource == '30' ||
            searchDate.projectSource == '80' ||
            (searchDate.projectSource == '90' &&
              searchDate.auditState == '30')
            " v-else @click="watchTime(scope.row)">查看结业考试</el-button>
                  <el-button type="text" size="mini" style="padding: 0 5px"
                    @click="studyTimeSet(scope.row)">学习时间设置</el-button>
                  <el-button type="text" size="mini" style="padding: 0 5px"
                    @click="exportQuestion(scope.row, '10')">导出题库doc</el-button>
                  <el-button type="text" size="mini" style="padding: 0 5px"
                    @click="exportQuestion(scope.row, '20')">导出题库pdf</el-button>
                  <el-button type="text" size="mini" style="padding: 0 5px"
                    @click="setUploadPhoto(scope.row)">设置上传照片</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <el-dialog title="结业考试" :visible.sync="dialogExamination" width="600px" top="15%" :center="true"
      :before-close="doClose">
      <div style="display: flex; flex-direction: column">
        <!-- <div style="display: flex; align-items: center; margin-bottom: 15px">
          <span>试卷类型：</span>
          <div>
            <el-radio-group v-model="paperSource" size="small">
              <el-radio label="10" border>指定试卷</el-radio>
              <el-radio label="30" border>随机组卷</el-radio>
            </el-radio-group>
          </div>
        </div> -->
        <div>
          <span>考试名称：</span>
          <span>{{ modelData.paperName }}</span>
        </div>
        <div style="padding-top: 15px">
          <span>通过分数：</span>
          <span>{{ modelData.paperScore }}分</span>
        </div>
        <div style="padding-top: 15px; display: flex; align-items: center">
          <span style="width: 82px">考试时间：</span>
          <span v-if="modelData.paperId &&
            modelData.paperUserCount != 0 &&
            modelData.paperStartTime
            ">{{ modelData.paperStartTime | momentWu }} 至
            {{ modelData.paperEndTime | momentWu }}</span>
          <span v-else-if="modelData.paperId &&
            modelData.paperUserCount != 0 &&
            !modelData.paperStartTime
            ">无</span>
          <el-date-picker v-else v-model="modelData.paperTime" type="datetimerange" align="right"
            start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']"
            value-format="yyyy-MM-dd HH:mm:ss" size="small" style="display: flex"></el-date-picker>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" style="display: flex; justify-content: space-between">
        <div style="flex: 1; display: flex; justify-content: flex-start">
          <div class="btnBox">
            <el-button class="bgc-bv" @click="editTest">修改</el-button>
          </div>
          <div style="margin-right: 20px">
            <el-button @click="delTest">删除</el-button>
          </div>
        </div>
        <div style="flex: 1; display: flex; justify-content: flex-end">
          <div style="margin-right: 20px">
            <el-button @click="cancle">取消</el-button>
          </div>
          <div class="btnBox">
            <el-button class="bgc-bv" @click="Examinationedit"
              v-if="modelData.paperId && modelData.paperUserCount == 0">确定</el-button>
            <el-button class="bgc-bv" disabled style="
                background: #dcdfe6;
                border: 1px solid #dcdfe6;
                color: #606266;
              " v-else>确定</el-button>
          </div>
        </div>
      </span>
    </el-dialog>
    <Mytestpaper ref="Mytestpaper" @eventBus="cancle" @getData="getData" />
  </div>
</template>
<script>
const className = "eduCertService";
import { mapGetters } from "vuex";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import Mytestpaper from "@/views/classListCom/classDetailsFile/popup/Mytestpaper";
import moment from "moment";
export default {
  name: "classListCom/startClassRecord",
  components: {
    Empty,
    PageNum,
    Mytestpaper,
  },
  mixins: [List],
  data() {
    return {
      searchDate: {
        projectId: "", //班级Id
        projectSource: "",
        auditState: "",
        areaId: "", // 区划id
      },
      totalDuration: 0, // 班级课程总时长
      dialogExamination: false, //弹框
      modelData: {
        paperName: "",
        paperScore: "",
        paperStartTime: "",
        paperEndTime: "",
        paperTime: [],
      }, //弹框数据
      paperSource: "10", //试卷类型
    };
  },
  created() { },
  watch: {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  mounted() {
    this.searchDate.projectId = this.$route.query.projectId;
    this.searchDate.projectSource = this.$route.query.projectSource;
    this.searchDate.auditState = this.$route.query.auditState;
    this.searchDate.areaId = this.$route.query.areaId;
    this.getTableHeight();
    this.getCourseHour();
  },
  methods: {
    //取消
    cancle() {
      this.dialogExamination = false;
    },
    //预览课件
    seeDetails(row) {
      this.$router.push({
        path: "/web/detailResource",
        query: {
          courseId: row.courseId,
          projectCourseId: row.projectCourseId,
          auditState: 20,
          projectSource: this.searchDate.projectSource,
          stu: "classLook",
          areaId: this.searchDate.areaId
        },
      });
    },
    editTest() {
      this.dialogExamination = false;
      setTimeout(() => {
        console.log(this.modelData);
        sessionStorage.setItem("paperTotalScore", "");
        this.$refs.Mytestpaper.showPopUp(
          this.modelData.paperTotalScore,
          this.modelData.projectCourseId,
          this.modelData.paperScore,
          this.modelData.paperName,
          this.modelData.paperId,
          this.modelData.paperStartTime,
          this.modelData.paperEndTime
        );
      }, 200);
    },
    delTest() {
      this.$post("/biz/projectCourse/deleteProjectCoursePaper", {
        projectCourseId: this.modelData.projectCourseId,
      }).then((res) => {
        if (res.status == "0") {
          this.dialogExamination = false;
          this.$message({
            message: res.message,
            type: "success",
          });
          this.getData(-1);
        }
      });
    },
    //设置或查看结业考试时间：考试ID不存在，添加试卷；考试ID存在，考试人数为0，设置考试时间；其他为查看
    watchTime(row) {
      let id = row.paperId || "";
      let num = row.paperUserCount || "";
      // this.dialogExamination = true;
      if (!row.paperId) {
        sessionStorage.setItem("paperTotalScore", "");
        this.$refs.Mytestpaper.showPopUp(
          "",
          row.projectCourseId,
          row.paperScore,
          row.paperName,
          row.paperId,
          row.paperStartTime,
          row.paperEndTime
        );
      } else if (!row.paperUserCount && row.paperUserCount != 0) {
        //查看
        this.modelData = {
          projectCourseId: row.projectCourseId,
          paperId: row.paperId,
          paperName: row.paperName,
          paperScore: row.paperScore,
          paperStartTime: row.paperStartTime,
          paperEndTime: row.paperEndTime,
          paperUserCount: row.paperUserCount,
          paperTotalScore: row.paperTotalScore,
          paperTime: [],
        };
        if (row.paperStartTime && row.paperEndTime) {
          this.modelData.paperTime = [
            this.$moment(row.paperStartTime).format("YYYY-MM-DD HH:mm:ss"),
            this.$moment(row.paperEndTime).format("YYYY-MM-DD HH:mm:ss"),
          ];
        }
        this.dialogExamination = true;
      } else {
        //修改时间
        this.modelData = {
          projectCourseId: row.projectCourseId,
          paperId: row.paperId,
          paperName: row.paperName,
          paperScore: row.paperScore,
          paperStartTime: row.paperStartTime,
          paperEndTime: row.paperEndTime,
          paperUserCount: row.paperUserCount,
          paperTotalScore: row.paperTotalScore,
          paperTime: [],
        };
        if (row.paperStartTime && row.paperEndTime) {
          this.modelData.paperTime = [
            this.$moment(row.paperStartTime).format("YYYY-MM-DD HH:mm:ss"),
            this.$moment(row.paperEndTime).format("YYYY-MM-DD HH:mm:ss"),
          ];
        }
        this.dialogExamination = true;
      }
    },
    //设置考试时间
    Examinationedit() {
      if (this.modelData.paperTime && this.modelData.paperTime.length > 0) {
        this.modelData.paperStartTime = this.modelData.paperTime[0];
        this.modelData.paperEndTime = this.modelData.paperTime[1];
        this.$post("/run/projectCourse/updateProjectCoursePaperTime", {
          paperEndTime: this.modelData.paperEndTime,
          paperStartTime: this.modelData.paperStartTime,
          projectCourseId: this.modelData.projectCourseId,
        }).then((res) => {
          if (res.status == "0") {
            this.dialogExamination = false;
            this.modelData = {};
            this.getData(-1);
          }
        });
      } else {
        this.$post("/run/projectCourse/updateProjectCoursePaperTime", {
          paperEndTime: "",
          paperStartTime: "",
          projectCourseId: this.modelData.projectCourseId,
        }).then((res) => {
          if (res.status == "0") {
            this.dialogExamination = false;
            this.modelData = {};
            this.getData(-1);
          }
        });
      }
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.searchDate.projectId || this.$route.query.projectId) {
        params.projectId =
          this.searchDate.projectId || this.$route.query.projectId;
      }
      this.doFetch({
        url: "/run/projectCourse/listPage",
        params,
        pageNum,
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
    getTime(val) {
      if (val) {
        let h = parseInt(val / 60 / 60);
        let m = parseInt((val % 3600) / 60);
        let s = val % 60;
        if (h < 9) {
          h = "0" + h;
        }
        if (m < 9) {
          m = "0" + m;
        }
        if (s < 9) {
          s = "0" + s;
        }
        return (
          (h != "00" ? h + "时" : "0时") +
          (m != "00" ? m + "分" : "0分") +
          (s != "00" ? s + "秒" : "0秒") || ""
        );
      } else {
        return "0秒";
      }
    },
    getCourseHour() {
      this.$post("run/projectCourse/classcourseduration", {
        projectId: this.$route.query.projectId,
      }).then((ret) => {
        if (ret.status == "0") {
          this.totalDuration = this.getTime(ret.data.totalDuration);
        }
      });
    },
    //下载课表
    downloadTimetable(courseId, projectCourseId) {
      this.$post("/run/projectCourse/export/classcourse", {
        courseId,
        projectCourseId,
      }).then((ret) => {
        if (ret.status == "0") {
          window.open(ret.data.url);
        }
      });
    },
    //下载讲师资质
    downloadQualification(courseName, projectCourseId, operateQuery) {
      this.$post(
        "/sys/downloadTeacherQualification/asyncDownloadQualification",
        { courseName, projectCourseId, operateQuery }
      ).then((res) => {
        if (res.status == "0") {
          let list = res.data;
          if (!this.downloadItems.includes(list.taskId)) {
            this.$store.dispatch("pushDownloadItems", list.taskId);
          } else {
            this.$message.warning(
              "[" + list.fileName + "]已经申请下载,请耐心等待"
            );
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //学习时间设置
    studyTimeSet(row) {
      this.$router.push({
        path: "/web/classListCom/startClassRecordCourseDetail",
        query: {
          courseName: row.courseName,
          projectId: row.projectId,
          projectCourseId: row.projectCourseId,
        },
      });
    },
    // 设置上传照片
    setUploadPhoto(row) {
      this.$router.push({
        path: "/web/classListCom/setUploadPhoto",
        query: {
          courseName: row.courseName,
          projectId: row.projectId,
          projectCourseId: row.projectCourseId,
        },
      });
    },
    //导出doc||pdf
    exportQuestion(row, stu) {
      let parma = {
        exportType: stu,
        projectCourseId: row.projectCourseId,
        projectId: row.projectId,
        courseName: row.courseName,
      };
      this.$post("/run/projectCourse/exportQuestion", parma).then((res) => {
        if (res.status == "0") {
          let list = [];
          list.push(res.data);
          for (let item of list) {
            console.log(item);
            if (!this.downloadItems.includes(item.taskId)) {
              this.$store.dispatch("pushDownloadItems", item.taskId);
            } else {
              this.$message.warning(
                "[" + item.fileName + "]已经申请下载,请耐心等待"
              );
            }
          }
        } else {
          this.$message.error(re.message);
        }
      });
    },
  },
};
</script>
<style lang="less"></style>
